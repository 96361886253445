import './App.css';
import img from './assets/red_corn.jpeg'

function App() {
  return (
    <div className='App bg-gradient-to-t from-amber-600 to-amber-50'>
      <img
        src={img}
        className='absolute h-full w-full object-cover opacity-35 blur-sm z-1'
      />

      <div className='App-header font-dafoe'>
        <div className='text-6xl z-10'>
          Heartwood
        </div>
      </div>
      
      <div className='px-1 grid grid-cols-2 gap-2 justify-center'>
        <div className='py-10 text-3xl md:text-5xl font-medium z-10'>New Album</div>
        <div className='py-20 text-3xl md:text-5xl font-medium z-10'>Coming Soon</div>
      </div>

      <div className='px-3 py-10 grid grid-cols-1 gap-10 justify-center'>
        <div className='text-2xl z-10'>11.18.2024</div>
        <div className='text-7xl z-10 md:text-9xl font-glory font-bold'>Here I Am</div>
      </div>

      <div className='hidden px-1 py-10 grid sm:grid-cols-3 md:grid-cols-5 gap-2 justify-normal'>
        <a href='#' className='justify-start z-10 font-semibold hover:'>Spotify</a>
        <a href='#' className='justify-start z-10 font-semibold hover:'>Apple Music</a>
        <a href='#' className='justify-start z-10 font-semibold hover:'>Amazon Music</a>
      </div>


    </div>
  );
}

export default App;
